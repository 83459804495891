body {
  margin: 0;
  padding: 0;
}

/* Toolbar Styles */
.ql-toolbar {
  background-color: var(--joy-palette-neutral-softBg, #f6f7f9);
  border: 1px solid var(--joy-palette-neutral-outlinedBorder, #e0e3e7);
  border-radius: var(--joy-radius-md, 8px) var(--joy-radius-md, 8px) 0 0;
  box-shadow: var(--joy-shadow-sm, 0px 1px 1px rgba(0, 0, 0, 0.05));
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
}

.ql-container {
  border-radius: 0 0 var(--joy-radius-md, 8px) var(--joy-radius-md, 8px);
}

/* General Styles */
.quill-content {
  font-family: var(--joy-fontFamily-body, 'Inter', sans-serif);
  font-size: 1rem;
  line-height: 1.5;
  color: var(--joy-palette-text-primary, #2d3748);
  overflow: hidden;
}

/* Headings */
.quill-content h1,
.quill-content h2,
.quill-content h3,
.quill-content h4,
.quill-content h5,
.quill-content h6 {
  font-weight: 600;
  margin: 8px 0 4px;
  color: var(--joy-palette-text-primary, #1a202c);
}

.quill-content h1 {
  font-size: 1.875rem; /* ~30px */
}

.quill-content h2 {
  font-size: 1.5rem; /* ~24px */
}

.quill-content h3 {
  font-size: 1.25rem; /* ~20px */
}

.quill-content h4 {
  font-size: 1.125rem; /* ~18px */
}

/* Paragraphs */
.quill-content p {
  margin: 4px 0;
}

/* Links */
.quill-content a {
  color: var(--joy-palette-primary-600, #1e88e5);
  text-decoration: none;
  transition: color 0.2s;
}

.quill-content a:hover {
  text-decoration: underline;
}

/* Lists */
.quill-content ul,
.quill-content ol {
  margin: 4px 0;
  padding-left: 24px;
}

.quill-content ul li,
.quill-content ol li {
  margin-bottom: 4px;
  list-style-position: outside;
  color: var(--joy-palette-text-primary, #2d3748);
}

/* Blockquotes */
.quill-content blockquote {
  border-left: 4px solid var(--joy-palette-neutral-softActiveBg, #cfd8dc);
  margin: 4px 0;
  padding: 4px 8px;
  color: var(--joy-palette-neutral-700, #5a5a5a);
  background-color: var(--joy-palette-neutral-softBg, #f6f7f9);
  border-radius: var(--joy-radius-sm, 6px);
  font-style: italic;
}

/* Images */
.quill-content img {
  max-width: 100%;
  border-radius: var(--joy-radius-md, 8px);
  box-shadow: var(--joy-shadow-sm, 0px 2px 4px rgba(0, 0, 0, 0.1));
  margin: 4px 0;
}

/* Code Blocks */
.quill-content pre {
  background-color: var(--joy-palette-neutral-softBg, #f6f7f9);
  border-radius: var(--joy-radius-sm, 6px);
  padding: 12px;
  font-family: var(--joy-fontFamily-code, 'Source Code Pro', monospace);
  overflow-x: auto;
  color: var(--joy-palette-neutral-800, #333);
}

.quill-content code {
  background-color: var(--joy-palette-neutral-softBg, #f6f7f9);
  padding: 2px 4px;
  border-radius: var(--joy-radius-sm, 6px);
  font-family: var(--joy-fontFamily-code, 'Source Code Pro', monospace);
  color: var(--joy-palette-primary-700, #1976d2);
}

/* Tables */
.quill-content table {
  border-collapse: collapse;
  width: 100%;
  margin: 4px 0;
  font-size: 1rem;
}

.quill-content table th,
.quill-content table td {
  border: 1px solid var(--joy-palette-neutral-outlinedBorder, #e0e3e7);
  padding: 4px;
  text-align: left;
}

.quill-content table th {
  background-color: var(--joy-palette-neutral-softBg, #f6f7f9);
  font-weight: 600;
}

/* Horizontal Rule */
.quill-content hr {
  border: none;
  border-top: 1px solid var(--joy-palette-neutral-outlinedBorder, #e0e3e7);
  margin: 4px 0;
}
